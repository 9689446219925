import tasksMixin from "./tasks";

// TODO: refactoring (move permissions from mixin to global vuex state)

export default {
    data() {
        return {
            $currentUserRole: window.CurrentUserRole,
            $currentUserEmail: window.CurrentUserEmail,
            $currentUserId: window.CurrentUserId,
            $permissions: null,
        }
    },

    mixins: [tasksMixin],

    methods: {
        // search for permissions from Blade
        $can(permissionName) {
            return Permissions.indexOf(permissionName) !== -1;
        },

        isAdmin() {
            return this.currentUserRole === "admin";
        },
    },

    computed: {
        currentUserRole() {
            return this.$data.$currentUserRole;
        },

        postsPermissions() {
            return {
                canSeePosts: function () {
                    return this.$data.$permissions.tasks.canSeePostsTab;
                }.bind(this),
            }
        },

        tasksPermissions() {
            return {
                canCreatePrivateTasks: function () {
                    return this.$data.$permissions.tasks.canCreatePrivateTasks;
                }.bind(this),
                canEditTask: function (task) {
                    return this.isAssignedToTask(task) || this.isTaskOwner(task) || this.isAdmin();
                }.bind(this),
                canDeleteTask: function (task) {
                    return (
                        (this.isTaskOwner(task) && this.$data.$permissions.tasks.canDeleteOwnedTasks) ||
                        this.isAdmin()
                    )
                }.bind(this),
                canCloseTask: function(task) {
                    return this.isAssignedToTask(task) || this.isTaskWatcher(task) || this.isTaskOwner(task) || this.isAdmin();
                }.bind(this),
                canCreateSubtask: function (task) {
                    return this.isAssignedToTask(task) || this.isTaskOwner(task) || this.isAdmin();
                }.bind(this),
                canCreateTaskComment: function(task) {
                    return this.isAssignedToTask(task) || this.isTaskWatcher(task) || this.isTaskOwner(task) || this.isAdmin() || this.isParentTaskOwner();
                }.bind(this),
                canEditTaskComment: function (comment) {
                    return this.isCommentOwner(comment);
                }.bind(this),
                canMentionUsersInTaskComment: function () {
                    return this.$data.$permissions.tasks.canMentionUsersInTaskComment;
                }.bind(this),
                canSeeTaskHistory: function () {
                    return this.$data.$permissions.tasks.canSeeTaskHistory;
                }.bind(this),
            }
        },
    },

    created() {
        // custom permissions for frontend use
        this.$data.$permissions = Object.freeze({
            posts: {
                canSeePostsTab: this.currentUserRole === "guest" ? false : true,
            },
            tasks: {
                canCreatePrivateTasks: this.currentUserRole === "guest" ? false : true,
                canDeleteOwnedTasks: true,
                canDeleteAssignedTasks : false,
                canDeleteWatchedTasks : false,
                canMentionUsersInTaskComment: true,
                canSeeTaskHistory: this.currentUserRole === "guest" ? false : true,
            },
        });
    },
};

