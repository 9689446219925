import _ from "lodash";
import Popper from "popper.js";
import jQuery from "jquery";
import Pusher from "pusher-js";
import "bootstrap";
window._ = _;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

window.Popper = Popper;
window.$ = window.jQuery = jQuery;

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Echo from "laravel-echo";

window.Pusher = Pusher;

window.Echo = new Echo({
    authEndpoint: window.appEndpoint + "/broadcasting/auth",
    broadcaster: "pusher",
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true,
});
