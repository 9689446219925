export default {
    methods: {
        isAssignedToTask(task) {
            if(!task.user_connections)
                return false;

            let index = task.user_connections.filter(e => e.assigned).findIndex(
                con => con.user.id === this.$data.$currentUserId
            );

            return index >= 0;
        },

        isTaskWatcher(task) {
            if(!task.user_connections)
                return false;
            
            let index = task.user_connections.filter(e => e.watcher).findIndex(
                con => con.user.id === this.$data.$currentUserId
            );

            return index >= 0;
        },

        isTaskOwner(task) {
            return (task.owner.id === this.$data.$currentUserId);
        },

        isParentTaskOwner(task) {
            if (task.task_id == null || task.parent == null) {
                return false
            }
            return (task.parent.owner_id === this.$data.$currentUserId);
        },

        isCommentOwner(comment) {
            return (comment.owner.id === this.$data.$currentUserId);
        },
    },
}